import * as React from 'react';
import styled from '../../theme';
import { IContentFulProject } from '../../interfaces/project';
import { ICategory } from '../../interfaces/category';

const Item = styled.li`
  width: 100%;
  z-index: 0;
  @media all and (min-width: 50em) {
    width: 33.333%;
  }
`;

const Anchor = styled.div`
  display: block;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  color: white;
  text-decoration: none;
  &::before {
    content: '';
    display: block;
    padding-bottom: 75%;
  }
`;

const Inner = styled.div`
  position: absolute;
  bottom: 60px;
  left: 40px;
  right: 40px;
  z-index: 3;
  transition: all 600ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  ${Anchor}:hover & {
    bottom: 170px;
  }
  @media all and (min-width: 50em) {
    bottom: 3vw;
    left: 3vw;
    right: 3vw;
  }
`;

const Technologies = styled.span`
  display: block;
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-style: italic;
  color: white;
`;

const ProjectTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1;
  margin: 0px;
  color: white;
  margin-bottom: 3px;
  max-width: 80%;
  transition: color 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
`;

const Description = styled.p`
  position: absolute;
  left: 0;
  top: 100%;
  opacity: 0;
  color: white;
  transform: translateY(30%);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  font-family: 'Gilroy', sans-serif;
  margin: 0px;
  max-width: 80%;
  transition: color 400ms cubic-bezier(0.7, 0, 0.3, 1),
    transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms,
    opacity 300ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  ${Anchor}:hover & {
    opacity: 1;
    color: black;
    transform: translateY(0%);
  }
`;

const ProjectColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  transform: translateY(120%) skewY(10deg);
  background-color: #232577;
  transition: transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 100ms;
  ${Anchor}:hover & {
    transform: translateY(0%) skewY(0deg);
    transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  }
`;

interface ImageProps {
  image: string;
  className?: string;
  url: string;
  children?: React.ReactChild | React.ReactChild[];
}

const ImageDiv: React.SFC<ImageProps> = ({ className, children, url }) => {
  if (url === null) {
    return <div className={className}>{children}</div>;
  }
  return (
    <div className={className} onClick={() => window.open(url, '_blank')}>
      {children}
    </div>
  );
};

const ProjectImage = styled(ImageDiv)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(${props => props.image});
  opacity: 1;
  transition: transform 400ms cubic-bezier(0.7, 0, 0.3, 1) 0ms,
    opacity 800ms cubic-bezier(0.7, 0, 0.3, 1);
  ${Anchor}:hover & {
    transform: translateY(-10%);
    transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 100ms;
  }
`;

interface Props {
  node: IContentFulProject;
}

const ProjectNode: React.SFC<Props> = ({ node }) => (
  <Item>
    <Anchor>
      <Inner>
        <Technologies>
          {node.categories &&
            node.categories.map((c: ICategory) => (
              <span key={c.id}> / {c.title}</span>
            ))}
        </Technologies>
        <ProjectTitle>{node.projectTitle}</ProjectTitle>
        <Description>
          {node.shortDescription && node.shortDescription.shortDescription}
        </Description>
      </Inner>
      <ProjectColor />
      <ProjectImage
        image={node.image.length ? node.image[0].file.url : ''}
        url={node.url}
      />
    </Anchor>
  </Item>
);

export default ProjectNode;
