import * as React from 'react';
import { graphql } from 'gatsby';
import WorkPage from '../pages/work';
import { ICategory } from '../interfaces/category';
import { IPropsProject } from '../interfaces/project';

interface Props extends IPropsProject {}

export default class Project extends React.Component<Props, {}> {
  render() {
    const {
      projectTitle,
      shortDescription,
      projectDescription,
      image = [],
    } = this.props.data.contentfulProject;
    const contents =
      projectDescription !== null
        ? projectDescription.projectDescription.split('.')
        : [];
    return <WorkPage />;
  }
}

export const pageQuery = graphql`
  query projectQuery($id: String!) {
    contentfulProject(id: { eq: $id }) {
      id
      projectTitle
      shortDescription {
        shortDescription
      }
      projectDescription {
        projectDescription
      }
      image {
        id
        resolutions {
          src
          srcSet
        }
      }
    }
  }
`;
