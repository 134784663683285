import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from '../theme';
import { IProjectNode } from '../interfaces/project';
import Layout from '../components/layout';
import Container from '../components/sections/Container';
import ProjectNode from '../components/molecules/ProjectNode';

const ContentSection = styled.section`
  position: relative;
  z-index: 10;
`;

const WorkIntro = styled.div`
  padding-right: 40px;
  padding-bottom: 10vh;
  padding-top: 15vh;
  padding-left: 40px;
  position: relative;
  z-index: 10;
  @media all and (min-width: 50em) {
    padding-left: 50%;
    height: 100vh;
    padding-top: 50vh;
  }
`;

const WorkTitle = styled.h1`
  margin-bottom: 90px;
  font-size: 9.5rem;
  text-transform: uppercase;
  color: #1c1b20;
  @media all and (min-width: 50em) {
    position: absolute;
    top: 50vh;
    font-size: 13.6rem;
    left: 15%;
    transform: translateY(-50%);
    margin: 0;
    padding-bottom: 0px;
  }
`;

const WorkSubTitleContainer = styled.div`
  @media all and (min-width: 50em) {
    margin-left: 110px;
  }
`;

const WorkContentTitle = styled.p`
  line-height: 1.2;
  font-size: 2.6rem;
  margin-bottom: 30px;
  font-weight: 900;
  color: #1c1b20;
  @media all and (min-width: 50em) {
    font-size: 3.6rem;
  }
`;

const ProjectList = styled.ul`
  margin: 0px;
  list-style: none;
  position: relative;
  z-index: 20;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  transition: transform 600ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 50%;
    background: #1c1b20;
    left: 0;
    width: 100%;
  }
  @media all and (min-width: 50em) {
    margin-top: -15vh;
    padding: 0 40px;
  }
`;

interface Props {
  history?: History;
  location?: Location;
  data: {
    us: {
      edges: IProjectNode[];
    };
  };
}

const pageQuery = graphql`
  query PageQuery {
    us: allContentfulProject(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          id
          projectTitle
          image {
            file {
              url
            }
          }
          url
          shortDescription {
            shortDescription
          }
          categories {
            id
            title
          }
        }
      }
    }
  }
`;

const WorkPage: React.SFC<Props> = ({ location }) => (
  <Layout>
    <Container current={location ? location.pathname.substring(1) : ''}>
      <ContentSection>
        <WorkIntro>
          <WorkTitle>Work.</WorkTitle>
          <WorkSubTitleContainer>
            <WorkContentTitle>
              <span>A small slice of</span>
              <span>
                <i>projects I've worked on over the years</i>
              </span>
            </WorkContentTitle>
          </WorkSubTitleContainer>
        </WorkIntro>
      </ContentSection>
      <ProjectList>
        <StaticQuery
          query={pageQuery}
          render={data => (
            <>
              {data &&
                data.us.edges.map((edge: IProjectNode) => (
                  <ProjectNode key={edge.node.id} node={edge.node} />
                ))}
            </>
          )}
        />
      </ProjectList>
    </Container>
  </Layout>
);

export default WorkPage;
